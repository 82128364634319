import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import './style.scss'
import Uploader from 'components/shared/Forms/Uploader'
import LoadingDots from 'components/shared/Forms/LoadingDots'
import { button } from 'utils/styles'

const UploadBtn = styled('a')`
  display: flex;
  flex: 1;
  ${button.purple};
  ${button.big};
`

export default class VerifySocialForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      facebook: '',
      linkedin: '',
      twitter: '',
      instagram: '',
      submitted: false,
      error: '',
      loading: false,
    }
  }

  componentDidMount() {
    console.log(this.props)
  }

  onSubmit = async () => {
    const { facebook, linkedin, twitter, instagram } = this.state
    this.setState({ loading: true })
    const error = await this.props.onSubmit(
      facebook,
      linkedin,
      twitter,
      instagram
    )
    if (error) {
      console.log(error)
      return this.setState({ error, loading: false })
    }
    this.setState({ submitted: true, loading: false })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    let { facebook, linkedin, twitter, instagram, loading } = this.state
    return (
      <section className="form-page-container">
        <div className="auth-page-subcontainer">
          <p className="verification-txt">
            This form is not required, but helps to expediate the verification
            process. Your profiles will not be shared publically and will only
            be used for verification purposes.
          </p>
          <div className="auth-page-flex">
            <fieldset className="fieldset fieldset-with-label">
              <label htmlFor="fnme">Facebook profile</label>
              <input
                type="text"
                autoFocus
                maxLength="250"
                name="facebook"
                id="facebook"
                value={facebook}
                onChange={this.onChange}
              />
            </fieldset>
            <fieldset className="fieldset fieldset-with-label">
              <label htmlFor="fnme">LinkedIn profile</label>
              <input
                type="text"
                maxLength="250"
                name="linkedin"
                id="linkedin"
                value={linkedin}
                onChange={this.onChange}
              />
            </fieldset>
            <fieldset className="fieldset fieldset-with-label">
              <label htmlFor="fnme">Twitter profile</label>
              <input
                type="text"
                maxLength="250"
                name="twitter"
                id="twitter"
                value={twitter}
                onChange={this.onChange}
              />
            </fieldset>
            <fieldset className="fieldset fieldset-with-label">
              <label htmlFor="fnme">Instagram profile</label>
              <input
                type="text"
                maxLength="250"
                name="instagram"
                id="instagram"
                value={instagram}
                onChange={this.onChange}
              />
            </fieldset>
            <div className="bottom-btn-container step-1">
              <div>
                <UploadBtn onClick={this.onSubmit}>Submit</UploadBtn>
              </div>
            </div>
            <div className="loading-animation">
              <LoadingDots loading={loading} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
