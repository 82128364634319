import React from 'react'
import { navigate } from 'gatsby'

import './auth.scss'
import VerifySocialForm from 'components/Auth/SignUp/VerifySocial'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'

class VerifySocialPage extends React.Component {
  onSubmit = async (...args) => {
    try {
      await this.props.auth.verifySocial(...args)
      navigate(`/dashboard/settings`)
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <div id="verify-social-page">
        <div className="content-wrap">
          <section className="page-title form-title">
            <h2>Verify Social</h2>
          </section>
          <VerifySocialForm onSubmit={this.onSubmit} />
        </div>
      </div>
    )
  }
}

export default ({ location }) => (
  <Layout location={location} displayFooter={false} showBeacon={true}>
    <SEO title="Verify Phone" />
    <VerifySocialPage location={location} />
  </Layout>
)
